<template>
  <div class="group-wrap" ref="group">
    <div v-if="this.status === `success`">
      <h1>{{ photoGroup.date_text }}</h1>
      <div class="photos-group">
        <template v-for="(photo, index) of loadedPhotoGroup" :key="photo.id">
          <photo-render-background-div
            class="photo"
            :imageUrl="`/api/photos/${photo.id}/thumb`"
            :style="span()"
            @click="(event) => showPhotoDetailOfPhoto(event, index)"
          >
            <p>
              {{ photographedDaysAgo(photo.date_utc) }}
            </p></photo-render-background-div
          >
        </template>
        <Teleport to="#photo-detail">
          <Transition>
            <photo-detail
              v-if="showPhotoDetail"
              v-model:show-photo-detail="showPhotoDetail"
              v-model:showing="showing"
              :showingGroupEndpoint="`/api/photos/days/${photoGroup.date_grouping}`"
            />
          </Transition>
        </Teleport>
      </div>
    </div>
    <div v-else class="loading">Loading...</div>
  </div>
</template>

<script>
import moment from "moment";
import PhotoRenderBackgroundDiv from "@/components/PhotoRenderBackgroundDiv";
import PhotoDetail from "@/components/PhotoDetail";
import axios from "@/services/axios";

export default {
  props: { photoGroup: Object },
  components: {
    PhotoRenderBackgroundDiv,
    PhotoDetail,
  },
  data: function () {
    return {
      showPhotoDetail: false,
      showing: null,
      loadedPhotoGroup: false,
      status: "pending",
      observer: null,
    };
  },
  methods: {
    span() {
      const random = Math.floor(Math.random() * (3 - 1 + 1) + 1);
      if (random == 1) {
        return null;
      } else if (random == 2) {
        return `grid-column: span 2; grid-row: span 2`;
      } else if (random == 3) {
        return `grid-column: span 3; grid-row: span 3`;
      }
    },
    photographedDaysAgo(date) {
      return moment(date).fromNow();
    },
    dateToDate(date) {
      return moment(date).format("LL");
    },
    showPhotoDetailOfPhoto(event, index) {
      this.showing = index;
      this.showPhotoDetail = true;
    },
    async load() {
      try {
        this.status = "pending";
        const loadedPhotoGroupRequest = await axios({
          url: `/api/photos/days/${this.photoGroup.date_grouping}`,
          method: "GET",
          headers: { Authorization: "Bearer " + this.$store.state.token },
        });
        this.loadedPhotoGroup = loadedPhotoGroupRequest.data;
        this.status = "success";
      } catch (err) {
        this.status = "error";
        this.error = err;
      }
    },
    intersectionChange(entry) {
      entry.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return;
        }
        this.observer.unobserve(target);
        this.load().then(() => {
          console.log("Loaded group.");
        });
      });
    },
  },
  created() {
    this.observer = new IntersectionObserver(this.intersectionChange, {
      root: this.$refs.image,
      rootMargin: "600px",
      threshold: 0.01,
    });
  },
  mounted() {
    console.log(this.$refs.group);
    this.observer.observe(this.$refs.group);
  },
};
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: all 0.25s ease-in-out;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.loading {
  min-height: 100vh;
}
</style>
